import React, { useState, useEffect, createContext, useContext } from 'react';
import { supabase } from './index';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  HStack,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPatreon } from 'react-icons/fa6';
import { getPatreonBurstingTierLink, handlePatreonLink } from './patreonUtils';
import { updateSubscription, BURSTING_TIER, STUFFED_TIER, FREE_TIER, getNextFreeTokenResetDate, getNextPremiumTokenResetDate, TIER_TO_TOKEN_REFRESH_AMOUNT } from "./subscription_utils";
import { useRollbar } from '@rollbar/react';

// Create a context to hold user information
const UserContext = createContext(null);

function UserProvider({ children }) {
  const [userState, setUserState] = useState({
    user: null,
    username: null,
    subscriptionStatus: null,
  });
  const rollbar = useRollbar();

  useEffect(() => {
    async function fetchUserData() {
      try {
        const { data: { user } } = await supabase.auth.getUser();

        if (user) {
          const [userInfoResponse, subscriptionResponse] = await Promise.all([
            supabase
              .from('user_info')
              .select('username')
              .eq('user_id', user.id)
              .single(),
            supabase
              .from('subscriptions')
              .select('tier')
              .eq('user_id', user.id)
              .single()
          ]);

          setUserState({
            user,
            username: userInfoResponse.data?.username || null,
            subscriptionStatus: subscriptionResponse.data || null,
          });
        }
      } catch (error) {
        rollbar.error('Error loading user data:', error.message);
      }
    }

    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={userState}>
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  return useContext(UserContext);
}

function SettingsPage() {
  const { user, username, subscriptionStatus } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [patreonInfo, setPatreonInfo] = useState(null);
  const [isLoadingPatreonInfo, setIsLoadingPatreonInfo] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    fetchPatreonInfo();

    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam === 'third-party') {
      setActiveTab(1);
    }
  }, [location]);

  async function fetchPatreonInfo() {
    setIsLoadingPatreonInfo(true);
    try {
      const token = await supabase.auth.getSession();
      const response = await fetch('/api/get_patreon_info', {
        headers: {
          'Authorization': `Bearer ${token.data.session.access_token}`
        }
      });
      const data = await response.json();
      setPatreonInfo(data);
    } catch (error) {
      rollbar.error('Error fetching Patreon info:', error);
      toast({
        title: "Error fetching Patreon info",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingPatreonInfo(false);
    }
  }

  async function unlinkPatreon() {
    try {
      const token = await supabase.auth.getSession();
      const response = await fetch('/api/unlink_patreon', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token.data.session.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to unlink Patreon account');
      }

      await fetchPatreonInfo();
      toast({
        title: "Patreon account unlinked successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      await updateSubscription(token.data.session.access_token)

    } catch (error) {
      rollbar.error('Error unlinking Patreon account:', error);
      toast({
        title: "Error unlinking Patreon account",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function handleDeleteAccount() {
    setIsDeleting(true);
    try {
      const token = await supabase.auth.getSession();

      const response = await fetch('/api/delete_user', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token.data.session.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete account');
      }

      // Sign out the user locally
      await supabase.auth.signOut();

      toast({
        title: "Account deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Redirect to home page
      navigate('/');

    } catch (error) {
      rollbar.error('Error deleting account:', error);
      toast({
        title: "Error deleting account",
        description: "Please try again or contact support if the problem persists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
      onClose();
    }
  }

  if (!user || !subscriptionStatus) {
    return <Text>Loading user data...</Text>;
  }

  const getNextTokenResetDate = () => {
    return subscriptionStatus.tier === FREE_TIER
      ? getNextFreeTokenResetDate()
      : getNextPremiumTokenResetDate();
  };

  const getTokenResetAmount = () => {
    return TIER_TO_TOKEN_REFRESH_AMOUNT[subscriptionStatus.tier] || 0;
  };

  const formatDate = (date) => {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <Box maxWidth="800px" margin="auto" padding={5}>
      <Heading mb={6}>Settings</Heading>
      <Tabs index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList>
          <Tab>Account</Tab>
          <Tab>Third Party Accounts</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Text>
                <Text as="span" fontWeight="bold">Username: </Text>
                {username}
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">Email: </Text>
                {user.email}
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">Subscription Tier: </Text>
                {subscriptionStatus.tier}
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">Next Token Reset: </Text>
                {formatDate(getNextTokenResetDate())}
              </Text>
              <Text>
                <Text as="span" fontWeight="bold">Tokens at Next Reset: </Text>
                {getTokenResetAmount()}
              </Text>

              <Box pt={6} borderTop="1px" borderColor="gray.200">
                <Button
                  colorScheme="red"
                  onClick={onOpen}
                  width="full"
                >
                  Delete Account
                </Button>
              </Box>
            </VStack>

            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Account
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure you want to delete your account? This cannot be undone.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      No
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleDeleteAccount}
                      ml={3}
                      isLoading={isDeleting}
                    >
                      Yes, Delete My Account
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </TabPanel>
          <TabPanel>
            <VStack spacing={6} align="stretch">
              <Box>
                <Heading size="md" mb={2}>
                  <HStack>
                    <Text>{"Patreon"}</Text>
                    <Icon as={FaPatreon} boxSize={6} />
                  </HStack>
                </Heading>
                {isLoadingPatreonInfo ? (
                  <Text>Loading Patreon information...</Text>
                ) : patreonInfo && patreonInfo.has_linked_patreon ? (
                  <>
                    <Text>
                      <Text as="strong">Linked Account:</Text> {patreonInfo.user_url ? patreonInfo.user_url : patreonInfo.username}
                    </Text>
                    <Text>
                      <Text as="strong">Subscription Status For</Text>{' '}
                      <Link
                        href={getPatreonBurstingTierLink()}
                        color="blue.500"
                        isExternal
                      >
                        $20 Patreon Tier
                      </Link>
                      :{' '}
                      <Text
                        as="span"
                        color={patreonInfo.tier_user_subscribed_to === BURSTING_TIER ? "green.500" : "red.500"}
                        fontWeight="bold"
                      >
                        {patreonInfo.tier_user_subscribed_to === BURSTING_TIER ? 'Subscribed' : 'Not subscribed'}
                      </Text>
                    </Text>
                    <Text>
                      <Text as="strong">Subscription Status For</Text>{' '}
                      <Link
                        href={getPatreonBurstingTierLink()}
                        color="blue.500"
                        isExternal
                      >
                        $10 Patreon Tier
                      </Link>
                      :{' '}
                      <Text
                        as="span"
                        color={patreonInfo.tier_user_subscribed_to === STUFFED_TIER ? "green.500" : "red.500"}
                        fontWeight="bold"
                      >
                        {patreonInfo.tier_user_subscribed_to === STUFFED_TIER ? 'Subscribed' : 'Not subscribed'}
                      </Text>
                    </Text>
                    <Button onClick={() => handlePatreonLink(user.id)} mt={2}>Relink your Patreon Account</Button>
                    <Button onClick={unlinkPatreon} mt={2} ml={2} colorScheme="red">Unlink Patreon Account</Button>
                  </>
                ) : (
                  <Button onClick={() => handlePatreonLink(user.id)}>Link your Patreon Account</Button>
                )}
              </Box>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default function WrappedSettingsPage() {
  return (
    <UserProvider>
      <SettingsPage />
    </UserProvider>
  );
}