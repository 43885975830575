import React from 'react';
import { Box, Heading, Text, Container, VStack } from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { socialLinks } from './constants';

const HelpPage = () => {
    return (
        <Container maxW="container.md" py={8}>
            <Box>
                <Heading as="h1" mb={4}>
                    Help & Support
                </Heading>
                <VStack spacing={4} align="stretch">
                    <Text fontSize="lg">
                        If you need assistance or have any questions about spherify.ai,
                        please don't hesitate to reach out. You can email support at:{' '}
                        <strong>help@spherify.ai</strong>.
                    </Text>
                    <Text fontSize="lg">
                        Alternatively, you can ask for assistance in our{' '}
                        <ChakraLink href={socialLinks[0].url} isExternal color="blue.500">
                            Discord server
                        </ChakraLink>.
                    </Text>
                </VStack>
            </Box>
        </Container>
    );
};

export default HelpPage;